.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.popup {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eceef4;
    background: #fff;
    box-shadow: 0px 48px 96px 0px rgba(0, 7, 32, 0.08);
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
}
