.adminPanelContainerStyles {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 32px 32px 32px;
    background-color: #F8F8F8;
}

/* top header block styles start */
.headerContainerStyles {
    /* position: sticky; */
    /* top: 0; */
    /* z-index: 1; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leftHeadingViewStyles {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.adminTextStyles,
.storesTextStyles {
    font-family: "Inter-Medium", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}

.inputAndLogOutViewStyles {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.logOutBtnStyles {
    background-color: var(--black);
}

.dividerStyles {
    width: 1px;
    height: 40px;
    border: 1px solid #000;
}

.searchInputStyles {
    width: 455px;
    max-width: 100%;
    max-height: 63px;
    height: 100%;
    border-radius: 26px;
    font-family: "Inter-Light", sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #F8F8F8;
    border: 0.5px solid #000;
    color: #000;
}

/* top header block styles end */

/* bottom content block styles start */
.bottomMainBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 64px;
    justify-content: space-between;
}

.storeDataBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.storeCityDataViewStyles {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.borderStyles {
    border-top: 2px solid #000;
}

.cityDescViewStyles {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding-bottom: 32px;
}

.cityHeadingStyles,
.cityNameStyles {
    font-family: "Inter-Bold", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.noDataFoundStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: "Inter-Bold", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black36);
}

/* bottom content block styles end */