.inputContainer {
    position: relative;
    width: 100%;
}

.inputIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 21px;
    height: 21px;
}
.inputStyles {
    width: 100%;
}

.inputStyles:focus {
    outline: 1px solid var(--blue255);
}
.errorStyles {
    color: var(--red);
    font-size: 18px;
    font-family: "Medium", sans-serif;
    line-height: 30px;
}
.inputImageStyles {
    width: 100%;
    height: 100%;
}

.optionsContainer {
    border: 2px solid var(--lightGray3);
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
    position: absolute;
    z-index: 10;
}

.optionItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--white);
    color: var(--black);
    font-family: "Inter-Regular", sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
}
.optionItem:nth-child(odd) {
    /* background-color: red; */
    border-bottom: 2px solid var(--lightGray3);
}
.noBorder {
    border: none;
}
