.containerViewStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.lableTextStyle {
    color: var(--black);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}
.selecterViewStyle {
    width: 90%;
    margin-top: 4px;
}
.dropDownIconViewStyle {
    width: 15px;
    height: 12px;
    object-fit: cover;
    margin-right: 5px;
}
.imgStyle {
    width: 100%;
    height: 100%;
}

.dropDownViewStyle {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
}
.containerFocused {
    border: 2px solid blue;
    box-shadow: 0 0 5px blue;
}

.addIconStyles {
    width: 10%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customButtonStyle {
    cursor: pointer;
}

.divider {
    height: 40px;
    border-right: 1px solid #d6d6d6;
}
