.containerStyle {
    background: var(--white);
    width: 100%;
    border-radius: 20px;
    padding: 36px;
    height: auto;
    overflow: hidden;
}
.topSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 56px;
}
.inputSectionStyle {
    width: 100%;
}
.customInputViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    height: 100%;
    border-radius: 47px;
    border: 1px solid var(--black);
    background: var(--white);
}
.inputStyle {
    /* height: 30px; */
    height: 60px;
    border-radius: 47px;
    border: 1px solid var(--black);
    background: var(--white);
    border: none;
}
.customRightIconViewStyle {
    margin-right: 15px;
}
.btnViewStyle {
    width: 206px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
}
.bottomTableViewStyle {
    width: 100%;
    border-radius: 30px;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px var(--blackTransparent15);
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: calc(100% - 70px);
    height: auto;
    overflow: scroll;
}
.bottomTableViewStyle::-webkit-scrollbar {
    display: none;
}
.tableSectionViewStyle {
    margin-top: 33px;
    height: calc(100% - 70px);
}
table,
tr,
td {
    padding: 0;
    margin: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    border-collapse: collapse;
    vertical-align: middle;
    border-radius: 30px;
    width: 100%;
    border-spacing: 0px;
}
.tableViewStyle {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    overflow: hidden;
    box-shadow: 0 0 20px var(--blackTransparent15);
    margin-top: 20px;
}
.headingStyle2 {
    width: calc(100% / 12);
    color: var(--black);
    font-family: "Inter-Bold", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.iDHeadingStyle {
    width: calc(100% / 12);
    color: var(--black);
    text-align: center;
    font-family: "Inter-Bold", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.headingStyle td:last-child {
    border-right: none;
}
.serialNoStyle {
    /* text-align: center; */
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.serialNoTextStyle {
    background-color: var(--lightGray);
    height: 100%;
    width: 35%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    text-align: center;
    font-family: "Inter-Light";
    font-size: 16px;
}
.idSectionStyle {
    padding: 0px 10px;
    text-align: center;
    height: 100%;
}
.idSectionSubStyle {
    padding: 10px 5px;
}
.assetInfoStyle {
    /* text-align: center; */
    width: calc(100% / 7);
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
}
.statusStyle {
    width: 128px;
    color: var(--lightSkyBlue);
}
.lastActivityStyle,
.nextActivityStyle {
    width: 256px;
}
.avatharStyle {
    width: 24px;
    height: 24px;
}
.assetListDataRowStyle {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    color: #1e1e20;
    height: 70px;
}
.assetListDataRowStyle:first-child .serialNoStyle .serialNoTextStyle {
    border-radius: 20px 20px 0px 0px;
}
.assetListDataRowStyle:last-child .serialNoStyle .serialNoTextStyle {
    border-radius: 0px 0px 20px 20px;
}
.assetListDataRowStyle:last-child .serialNoStyle .serialNoTextStyle2 {
    border-radius: 60px;
    height: 60%;
}
.editBtnViewStyle {
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
    padding: 6px 15px;
}
.viewProfileBtnViewStyle {
    color: var(--black);
    border-radius: 30px;
    border: 1px solid var(--blackTransparent8);
    background: var(--blackTransparentGradientTwo);
    padding: 6px 15px;
}
