.dashboardContainerStyle {
    /* max-width: 1440px; */
    width: 100%;
    height: 100vh;
    background: var(--layoutColor);
    position: relative;
}
.dashboardSubContainerStyle {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.topSectionStyle {
    display: flex;
    flex-direction: column;
}
.topSectionSubStyle {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
}
.tabSectionStyle {
    display: flex;
    gap: 28px;
}
.tabViewStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 35px;
    border-radius: 22px 22px 0px 0px;
    background: var(--blackTransparent22);
    cursor: pointer;
}
.activeTabBgColor {
    background: var(--white);
}
.tabTextStyle {
    margin: 0;
    color: var(--white);
    text-align: center;
    font-size: 25px;
    font-family: "Inter-Light", sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.activeTabTextStyle {
    color: var(--black);
}
.cartStyle {
    color: var(--black);
}
.cartImgViewStyle {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.shoppingImgStyle {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.cartImgViewStyle {
    position: relative;
    display: block;
    overflow: hidden;
}
.cartCount {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 0;
    right: 0;
    z-index: 2;
    border-radius: 50%;
    color: var(--black);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
}

.outletSectionStyle {
    display: flex;
    height: calc(100vh - 138px);
    width: 100%;
    border-radius: 20px;
    margin-top: -16px;
}
.outletSectionStyle2 {
    border-radius: 20px;
}
.footerSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    position: relative;
}
.activeBgColor {
    background: var(--white);
}
.bottomleftSectionStyle {
    position: absolute;
    width: 300px;
    left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.copyRightsTextViewStyle {
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyRightsTextStyle {
    color: var(--blackTransparent44);
    text-align: center;
    font-size: 14px;
    font-family: "Inter-Light", sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.logoutBtnStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 80px;
    height: 100%;
    cursor: pointer;
}
.logoutImgStyle {
    /* width: 20px;
    height: 20px; */
    width: 23px;
    height: 25px;
    object-fit: cover;
    margin-right: 8px;
}
.logoutTextStyle {
    color: var(--white);
    font-size: 14px;
    font-family: "Inter-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.setingsBtnStyle {
    height: 100%;
    cursor: pointer;
}
.settingTextStyle {
    color: var(--white);
    font-size: 14px;
    font-family: "Inter-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.customStyles {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    z-index: 10;
}
