.btnStyle {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    background-color: var(--skyBlue);
    box-shadow: 0px 4px 16px var(--lightSkyBlue12);
    color: var(--white);
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
}
.primaryBtnStyle {
    background-color: var(--skyBlue);
    box-shadow: 0px 4px 16px var(--lightSkyBlue12);
    color: var(--white);
}
.darkBtnStyle {
    background: #1e1e20;
    box-shadow: 0px 4px 16px var(--blackTransparent122);
    color: var(--white);
}
.whiteBtnStyle {
    background: var(--white);
    border: 1px solid var(--blackTransparent111);
    box-shadow: 0px 4px 16px var(--blackTransparent196);
    color: #1e1e20;
}
.iconViewStyle {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.imgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
