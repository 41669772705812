.adminPanelCardContainerStyles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--blackTransparent033);
    box-shadow: 2px 2px 13.800000190734863px 1px var(--blackTransparent033);
    width: 100%;
}

/* left block styles start */

.leftBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.topLogoStyles {
    width: 236px;
    height: 117px;
}

.imageStyles {
    width: 100%;
    height: 100%;
}

.bottomContentViewStyles {
    max-width: 390px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.storeDescriptionViewStyles {
    max-width: 454px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.storeNameTextStyles {
    font-family: "Inter-Bold", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.storeDescTextStyles {
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.storeDeatilsViewStyles {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.storeManagerHeadingStyles,
.storeEmailHeadingStyles {
    font-family: "Inter-Bold", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.storeManagerNameTextStyles,
.storeEmailTextStyles {
    max-width: 390px;
    width: 100%;
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

/* left block styles end */

/* right block styles start */
.rightBlockStyles {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    justify-content: end;
}

.viewMoreBtnStyles {
    background-color: var(--lightGrayE5);
    border: 1px solid var(--black);
    padding: 4px 16px;
    width: fit-content;
    height: 40px;
    font-family: "Inter-Regular", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    color: var(--black);
}

.storeAndProductDataBlockStyles {
    max-width: 410px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.storeDataBlockStyles {
    max-width: 415px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 16px;
    /* justify-content: center; */
    align-items: end;
    background-color: var(--lightGrayED);
    border-radius: 8px;
}

.noOfSellersViewStyles,
.productInStoreViewStyles,
.productsSoldViewStyles {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.noOfSellersHeadingStyles,
.productsInStoreHeadingStyles,
.productsSoldHeadingStyles {
    width: 100%;
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    white-space: nowrap;
}

.noOfSellersCountTextStyles,
.productInNumTextStyles,
.productSoldNumTextStyles {
    max-width: 104px;
    width: 100%;
    max-height: 28px;
    height: 100%;
    border: 1px solid var(--white);
    background-color: var(--white);
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    overflow-x: scroll;
    /* white-space: nowrap; */
}

.productDataBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.productsSoldHeadingStyles {
    padding: 0px 10px 0px 34px;
}

.salesDataBlockStyles {
    max-width: 415px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 16px;
    background-color: var(--black);
    border-radius: 8px;
    color: var(--white);
}

.salesHeadingStyles {
    font-family: "Inter-Bold", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.salesStasticsBlockStyles {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-end;
    align-items: end;
}

.salesToDayViewStyles,
.salesLastMonthViewStyles {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: flex-end;
}

.toDayHeadingStyles,
.lastMonthHeadingStyles {
    font-family: "Inter-Medium", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
}

.toDaySalesPriceTextStyles,
.lastMonthSalesPriceTextStyles {
    max-width: 133px;
    width: 100%;
    max-height: 28px;
    height: 100%;
    padding: 4px;
    background-color: var(--white);
    color: var(--black);
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    white-space: nowrap;
    overflow-x: scroll;

}

/* right block styles end */