@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loadingSpinner {
    width: 40px;
    height: 40px;
    border: 5px solid var(--lightPink);
    border-top: 5px solid var(--black36);
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinnerContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blackTransparent555);
    z-index: 3000;
}
