.verifyStoreContainerStyles {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.storeDetailsStyles {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* align-items: center; */
}

.verifyButtonStyles {
    /* width: 350px;
    max-width: fit-content; */
}
.storeHeadingStyles {
    font-family: "Inter-Regular", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}
.storeDetailsTextStyles {
    font-family: "Inter-Light", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}
.storeDescStyles {
    font-family: "Inter-Medium", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}
