.containerStyle {
    background: var(--white);
    width: 100%;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    /* align-items: center; */
    /* justify-content: space-between; */
}
.rightSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.customBtnStyle {
    width: 103px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
}
.leftSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 95%;
    gap: 18px;
}
.titleTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0px;
    white-space: nowrap;
}
.formViewStyle {
    margin-top: 36px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-inline: auto;
}
.sellerSectionViewStyle {
    display: flex;
    flex-direction: column;
}
.lableTextStyle {
    color: var(--black);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}
.sellerSubViewStyle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4px;
}
.sellerIdViewStyle {
    /* width: 100px; */
    height: 47px;
    border-radius: 30px;
    padding: 0px;
    padding-left: 12px;
    border: 0.5px solid var(--blackTransparent11);
    font-size: 18px;
    color: var(--white);
    background-color: var(--black);
}
.customInputViewStyle2 {
    /* width: 100px; */
    max-width: 130px;
    height: 53px;
    height: 47px;
    border-radius: 30px;
    padding: 0px;
    padding-left: 15px;
    margin-top: 4px;
    border: 0.5px solid var(--blackTransparent11);
    font-size: 18px;
    margin-left: 26px;
    background-color: var(--black);
    margin-top: 12px;
}
.customLeftIconViewStyle {
    width: 35px;
    height: 20px;
}
.eachSectionLeftViewStyle {
    width: 50%;
}
.eachSectionViewStyle {
    width: 50%;
}
.phoneAndEmailSectionViewStyle {
    width: 50%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}
.searchIconViewStyle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.imgStyle {
    width: 100%;
    height: 100%;
}
.selecterStyle {
    width: 100%;
    /* height: 47px; */
}
.btnViewStyle {
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
    margin-top: 12px;
    margin-left: 26px;
}
.sectionViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    /* gap: 26px; */
}
.customInputViewStyle {
    height: 53px;
    /* height: 47px; */
    border-radius: 7px;
    padding: 0px;
    padding-left: 12px;
    margin-top: 4px;
    border: 0.5px solid var(--blackTransparent11);
    font-size: 18px;
}
.lableDisableTextStyle {
    color: var(--lightGray211);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}
.otherLeftSectionStyle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 26px;
}
.commentInputViewStyle {
    width: 100%;
    /* height: 115px; */
    height: 157px;
    margin-top: 4px;
    padding: 12px;
    resize: none;
    border-radius: 7px;
    border: 0.5px solid var(--blackTransparent11);
    margin-left: 26px;
}
.commentlableTextStyle {
    color: var(--black);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    margin-left: 26px;
}
.saveBtnSectionViewStyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    padding-bottom: 30px;
}
.saveBtnStyle {
    width: 206px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
    font-size: 22px;
}
.createdTextStyle {
    color: var(--blackTransparent4);
    text-align: center;
    font-family: "Inter-Regular", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    margin-top: 5px;
}
.customErrorTextStyle {
    margin-left: 25px;
}
