.containerStyle {
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.subContainerStyle {
    width: 24px;
    height: 18px;
    background-color: var(--white);
    display: flex;

    align-items: center;
    justify-content: center;
    border: 1px solid var(--black);
    border-radius: 7px;
}
.containerInsideStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65px;
}
.imgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.percentageTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
}
.chackBoxLableTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0px;
    margin-top: 3px;
    text-align: center;
    width: 65px;
}
