.containerStyle {
    /* max-width: 1440px; */
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        180deg,
        #000 0%,
        rgba(7, 4, 148, 0.76) 52.08%,
        #332fff 100%
    );
}
.subContainerStyle {
    width: 470px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.inputSectionStyle {
    display: flex;
    flex-direction: column;
}

.inputSubSectionStyle,
.PasswordInputStyle {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.titleTextStyle {
    font-family: "Inter-Bold", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.erroMessageStyles {
    font-family: "Inter-Bold", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: red;
    text-align: start;
}

.btnSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.btnViewStyle {
    width: 125px;
    height: 55px;
    border-radius: 27.5px;
    border: 3px solid #fff;
    background-color: var(--black);
}
