.inputContainerStyle {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.inputSubContainerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}
.inputStyle {
    width: 100%;
    /* height: 42px; */
    height: 70px;
    background: var(--white);
    border: 1px solid var(--whiteTransparent8);
    border-radius: 8px;
    padding: 12px 16px;
    font-family: "Inter-Regular", sans-serif;
    color: #1e1e20;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}
.inputStyle::placeholder {
    color: var(--lightGray96);
}
.inputStyle:active,
.inputStyle:focus {
    outline: none;
}
.errorTextStyle {
    font-family: "Inter-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--redTransparent0);
    margin: 0px;
    margin-top: 5px;
}
.lableTextStyle {
    font-family: "Inter-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    /* margin: 0px; */
    color: var(--white);
}
.leftIconViewStyle {
    width: 20px;
    height: 20px;
}
.leftIconStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.rightIconViewStyle {
    width: 20px;
    height: 20px;
}
.rightIconStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
