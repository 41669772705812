.containerStyle {
    background: var(--white);
    width: 100%;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    /* align-items: center; */
    /* justify-content: space-between; */
}
.rightSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.customBtnStyle {
    width: 103px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
}
.leftSectionStyle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 95%;
    gap: 18px;
}
.titleTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0px;
    white-space: nowrap;
}
.formViewStyle {
    margin-top: 36px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-inline: auto;
}
.sellerSearchViewStyle {
    display: flex;
    flex-direction: column;
}
.lableTextStyle {
    color: var(--black);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}

.searchSellerSubViewStyle {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    gap: 26px;
    margin-top: 4px;
}
.searchSellerLeftViewStyle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.sellerInputStyles {
    /* width: 407px; */
    height: 47px;
    border-radius: 7px;
    border: 0.5px solid var(--black);
    background: var(--blackTransparent15);
    box-shadow: 0px 2px 2px 0px var(--blackTransparent12);
    color: var(--black);
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 0px 3px 14px;
}

.sellerMsgInfo {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--red);
}

.searchSellerRightViewStyle {
    width: 50%;
}
.searchIconViewStyle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.dropdownIcon {
    color: var(--lightGray73);
    width: 20px;
    height: 20px;
}

.imgStyle {
    width: 100%;
    height: 100%;
}
.selecterStyle {
    width: 100%;
    /* height: 47px; */
}
.btnViewStyle {
    /* width: 200px; */
    width: fit-content;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
}
.productNumberViewStyle,
.productUidStyle {
    display: flex;
    align-items: center;
    height: 47px;
    border-radius: 7px;
    padding: 0px;
    padding-left: 12px;
    max-width: 140px;
    width: 100%;
    background-color: var(--blackTransparent12);
    border: 1px solid var(--blackTransparent11);
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.productNumberTextStyle,
.productUidStyle {
    color: var(--black);
    font-family: "Inter-Regular", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}

.addBrandContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sectionViewStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 26px;
}

.brandSectionViewStyle {
    width: 90%;
}

.eachSectionViewStyle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.customInputViewStyle {
    height: 47px;
    border-radius: 7px;
    padding: 0px;
    padding-left: 12px;
    margin-top: 4px;
    border: 0.5px solid var(--blackTransparent11);
    font-size: 18px;
}
.checkboxSectionViewStyle {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.checkboxSubSectionViewStyle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
    margin-top: 4px;
}

.lableDisableTextStyle {
    color: var(--lightGray211);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}
.otherLeftSectionStyle {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 26px;
}
.commentInputViewStyle {
    width: 100%;
    height: 198px;
    margin-top: 4px;
    padding: 12px;
    resize: none;
    border-radius: 7px;
    border: 0.5px solid var(--blackTransparent11);
}
.saveBtnSectionViewStyles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    padding-bottom: 30px;
}

.errorMsgStyle {
    color: var(--red);
    font-family: "Inter-Semibold", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}
.saveBtnOneStyle {
    display: none;
}

.saveBtnStyle {
    width: 206px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: var(--black);
    font-size: 22px;
}
.createdTextStyle {
    color: var(--blackTransparent4);
    text-align: center;
    font-family: "Inter-Regular", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    margin-top: 5px;
}
.statusSectionStyle {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.statusBtnViewStyle {
    width: 106px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 16.5px;
    background: var(--gradientColorGreenTwo);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 4px;
}
.statusDropDownImgViewStyle {
    width: 15px;
    height: 15px;
}
.statusDropDownImgStyle {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.statusTextStyle {
    color: var(--blackTransparent8);
    text-align: center;
    font-family: "Inter-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
