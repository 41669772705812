.notFoundPageStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.pageNotFoundHeadingStyle {
    font-family: "Inter-Regular", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.redirectHeadingStyle {
    font-family: "Inter-Regular", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.redirectSubHeadingStyle {
    color: var(--skyBlue);
    font-weight: bold;
    cursor: pointer;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--skyBlue);
}