.containerStyle {
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        180deg,
        #000 0%,
        rgba(7, 4, 148, 0.76) 52.08%,
        #332fff 100%
    );
}
.subContainerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}
.eachSectionStyle {
    display: flex;
    width: 410px;
    height: 130px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}
.textStyle {
    color: #fff;
    text-align: center;
    font-family: "Inter-Medium", sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
