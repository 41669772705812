* {
    margin: 0px;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    display: none;
}

/********   fonts  *********/
@font-face {
    font-family: "Inter-Bold";
    src: url("./assets/fonts/Inter-Bold.ttf");
}

@font-face {
    font-family: "Inter-Semibold";
    src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "Inter-Light";
    src: url("./assets/fonts/Inter-Light.ttf");
}

:root {
    --white: #ffffff;
    --whiteTransparent8: #e8e8e8;
    --black: #000;
    --black20: #1e1e20;
    --black30: #1e1d1d;
    --black36: #383636;
    --black333: #333;
    --blackTransparent033: #00000033;
    --blackTransparent15: rgba(0, 0, 0, 0.15);
    --blackTransparent12: rgba(0, 0, 0, 0.12);
    --blackTransparent8: rgba(0, 0, 0, 0.8);
    --blackTransparent7: rgba(0, 0, 0, 0.7);
    --blackTransparent1: rgba(0, 0, 0, 0.1);
    --blackTransparent2: rgba(0, 0, 0, 0.2);
    --blackTransparent4: rgba(0, 0, 0, 0.4);
    --blackTransparent5: rgba(0, 0, 0, 0.5);
    --blackTransparent6: rgba(0, 0, 0, 0.6);
    --blackTransparent11: rgba(0, 0, 0, 1);
    --blackTransparent111: rgba(0, 0, 0, 0.11);
    --blackTransparent0: rgba(255, 255, 255, 0);
    --blackTransparent08: rgba(255, 255, 255, 0.8);
    --blackTransparent22: rgba(255, 255, 255, 0.2);
    --blackTransparent39: rgba(0, 0, 0, 0.39);
    --blackTransparent55: rgba(255, 255, 255, 0.5);
    --blackTransparent53: rgba(53, 53, 53, 0.1);
    --blackTransparent74: rgba(0, 0, 0, 0.74);
    --blackTransparent122: rgba(30, 30, 32, 0.12);
    --blackTransparent111: rgba(30, 30, 32, 0.1);
    --blackTransparent196: rgba(196, 196, 196, 0.12);
    --blackTransparent555: rgba(0, 0, 0, 0.05);
    --blackTransparent44: rgba(255, 255, 255, 0.4);
    --blackTransparent277: rgba(217, 217, 217, 0.1);
    --blackTransparentGradientOne: linear-gradient(0deg,
            rgba(31, 28, 28, 0.1) 0%,
            rgba(31, 28, 28, 0.1) 100%),
        rgba(0, 0, 0, 0);
    --blackTransparentGradientTwo: linear-gradient(0deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0.2) 100%),
        rgba(0, 0, 0, 0);
    --lightGray: #d3d3d3e3;
    --lightGrayE5: #e5e5e5;
    --lightGrayED: #EDEDED;
    --lightGray73: #797373;
    --lightGray211: lightgray;
    --lightGray96: #898f96;
    --lightGray3: #f3f3f3;
    --lightBlack: #1e1e20;
    --gradientColorRed: linear-gradient(0deg,
            rgba(255, 0, 0, 0.2) 0%,
            rgba(255, 0, 0, 0.2) 100%),
        rgba(0, 0, 0, 0);

    --greenTransparent2: rgba(0, 207, 83, 0.2);

    --gradientColorGreen: linear-gradient(0deg,
            rgba(85, 208, 42, 0.2) 0%,
            rgba(85, 208, 42, 0.2) 100%),
        rgba(0, 0, 0, 0);
    --gradientColorGreenTwo: linear-gradient(0deg,
            rgba(71, 199, 26, 0.4) 0%,
            rgba(71, 199, 26, 0.4) 100%),
        rgba(0, 0, 0, 0);
    --red: red;
    --redTransparent0: #ec0000;

    --skyBlue: #236bfe;
    --lightSkyBlue: rgba(35, 107, 254, 0.8);
    --lightSkyBlue12: rgba(35, 107, 254, 0.12);
    --gradientColorBlue: linear-gradient(180deg,
            rgba(0, 12, 167, 0.9) 0%,
            rgba(179, 184, 242, 0.85) 60.42%);

    --layoutColor: conic-gradient(from 163deg at 100% 100%,
            #000 54.37500178813934deg,
            rgba(7, 4, 148, 0.76) 176.2500035762787deg,
            #332fff 268.1250071525574deg);

    --lightPink: #cac9ca;
    --blue255: blue;
    --yellow: #ffed8e;
    --grey: #aeb6d6;
}