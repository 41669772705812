* {
    margin: 0;
    box-sizing: border-box;
}
.cartViewStyle {
    /* width: 30%; */
    background-color: var(--white);
    padding: 30px;
    max-height: 100vh;
    height: 100%;
    overflow: scroll;
}
.cartHeaserStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.closeImgViewStyle {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.closeImgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.emptySectionStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
}
.emptyTextStyle {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}

/* cart data styles */

.cartProductDetailsMainBlockStyle {
    height: 390px;
    max-height: 100%;
    /* justify-content: space-between; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding-top: 57px;
}

.cartProductDetailsBlockStyle {
    max-height: 390px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* justify-content: space-between; */
    overflow: scroll;
}
.cartProductDetailsBlockStyle::-webkit-scrollbar {
    display: block;
    height: 4px;
    width: 4px;
}

.cartProductDetailsBlockStyle::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--blackTransparent5);
    box-shadow: 0 0 1px var(--blackTransparent55);
}

.cartProductsDetailsSubBlockStyle {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.cartProductDescLeftBlockStyle {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.cartProductDateStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cartProductDateDescStyle {
    color: var(--blackTransparent39);
    text-align: start;
    font-family: "Inter-Light", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.deleteIconStyle {
    color: var(--red);
    font-family: "Inter-Light", sans-serif;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.cartProductsDetailsBlockStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cartProductsDetailsStyle {
    display: flex;
    flex-direction: row;
    gap: 18px;
    /* align-items: center; */
}

.cartProductDescriptionStyle {
    /* max-width: 310px; */
    max-width: 299px;
    width: 100%;
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.cartProductSerialNumberStyle,
.sumTextStyle,
.cartProductPrice {
    color: var(--black);
    font-family: "Inter-Light", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.totalPriceRightBlockStyle {
    display: flex;
    justify-content: flex-end;
}

.sumPriceStyle {
    color: var(--black);
    text-align: right;
    font-family: "Inter-Regular", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.paymentAndPurchaseBtnStyle {
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.cartPaymentBlockStyle {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.paymentButtonsStyle {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    padding-top: 49px;
}

.inActiveBtnStyle {
    width: 217px;
    height: 67px;
    border-radius: 12px;
    border: 1px solid var(--black);
    background: var(--blackTransparent277);
    justify-content: center;
    color: var(--black);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.activeBtnStyle {
    width: 217px;
    height: 67px;
    border-radius: 12px;
    border: 1px solid var(--black);
    background: var(--black30);
    color: var(--white);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
}

.commentViewStyle {
    /* width: 440px; */
    width: 100%;
    height: 123px;
    border-radius: 12px;
    border: 1px solid var(--blackTransparent6);
    background: var(--blackTransparent53);
    resize: none;
}

.confirmPurchaseBtnStyle,
.inActiveConfirmPurchaseBtnStyle {
    /* width: 440px; */
    width: 100%;
    height: 82px;
    border-radius: 12px;
    border: 1px solid var(--white);
    background: var(--greenTransparent2);
    justify-content: center;
    color: var(--blackTransparent74);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inActiveConfirmPurchaseBtnStyle {
    border: 1px solid var(--black);
    background: var(--blackTransparent277);
}

.confirmPurchaseBlockStyle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.errorMsgStyle {
    color: var(--red);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
