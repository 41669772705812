.containerStyle {
    background: var(--white);
    /* width: calc(100% - 40px); */
    width: 100%;
    border-radius: 20px;
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnViewStyle {
    width: 206px;
    height: 60px;
    border-radius: 30px;
    border: 2px solid var(--white);
    background: #000;
}
.bottomTableViewStyle {
    width: 100%;
    border-radius: 30px;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px var(--blackTransparent15);
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: calc(100% - 70px);
    height: auto;
    overflow: scroll;
}
.bottomTableViewStyle::-webkit-scrollbar {
    display: none;
}
.tableSectionViewStyle {
    width: 100%;
    margin-top: 33px;
    height: calc(100% - 70px);
}
table,
tr,
td {
    padding: 0;
    margin: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    border-collapse: collapse;
    vertical-align: middle;
    border-radius: 30px;
    width: 100%;
    border-spacing: 0px;
}
.tableViewStyle {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    /* border-radius: 30px; */
    overflow: hidden;
    box-shadow: 0 0 20px var(--blackTransparent15);
    margin-top: 20px;
}
.headingStyle2 {
    text-align: center;
    width: calc(100% / 12);
    color: var(--black);
    text-align: center;
    font-family: "Inter-Bold", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.headingStyle td:last-child {
    border-right: none;
}
.serialNoStyle {
    text-align: center;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.serialNoTextStyle {
    background-color: var(--lightGray);
    height: 100%;
    width: 60%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    text-align: center;
    font-family: "Inter-Light", sans-serif;
    font-size: 16px;
}

.assetInfoStyle {
    text-align: center;
    width: calc(100% / 12);
    color: var(--black);
    font-family: "Inter-Light";
}
.createdAtTextStyle {
    text-align: center;
    width: calc(100% / 12);
    color: var(--black);
    font-family: "Inter-Light";
    padding-left: 10px;
}

.assetListDataRowStyle {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    color: #1e1e20;
    height: 70px;
}
.assetListDataRowStyle:first-child .serialNoStyle .serialNoTextStyle {
    border-radius: 20px 20px 0px 0px;
}
.assetListDataRowStyle:last-child .serialNoStyle .serialNoTextStyle {
    border-radius: 0px 0px 20px 20px;
}
.assetListDataRowStyle:last-child .serialNoStyle .serialNoTextStyle2 {
    border-radius: 60px;
    height: 60%;
}
.statusSoldViewStyle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16.5px;
    background: var(--gradientColorRed);
}
.statusInStoreViewStyle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16.5px;
    background: var(--gradientColorGreen);
}
.statusPaidOutViewStyle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16.5px;
    background: var(--yellow);
}
.statusPackedViewStyle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16.5px;
    background: var(--grey);
}
.editBtnViewStyle {
    text-align: center;
    width: calc(100% / 12);
    font-family: "Inter-Light", sans-serif;
    font-size: 16px;
}
.editBtnViewStyle div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.editBtnViewStyle p {
    margin: 0px;
    border: 1px solid var(--blackTransparent8);
    padding: 10px 16px;
    border-radius: 30px;
    color: var(--blackTransparent8);
    text-align: center;
    font-family: "Inter-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.addBtnViewStyle {
    text-align: center;
    width: calc(100% / 12);
}
.addBtnViewStyle div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
}
.addBtnViewStyle p {
    margin: 0px;
    border: 1px solid var(--blackTransparent8);
    padding: 10px 16px;
    border-radius: 30px;
    color: var(--blackTransparent8);
    text-align: center;
    font-family: "Inter-Regular", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
}
.disableBtnViewStyle p {
    border: 1px solid var(--blackTransparent1);
    background: var(--blackTransparentGradientOne);
    color: var(--blackTransparent2);
}
